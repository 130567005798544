import { h } from 'preact'
import { route } from '../../services/router'
import { toMinutes } from '../../services/time'
import { Button } from '../Common/Button/Button'
import { Icon } from '../Common/Icon/Icon'
import styles from './Controls.less'

export const Controls = (props: {
	message: string
	timestamp: number
	toggleShowHistory: (show?: boolean) => void
}) => {
	const messageEncoded = encodeURIComponent(props.message)

	return (
		<div className={styles.controls}>
			<Button onClick={() => props.toggleShowHistory()}>History</Button>
			<Button
				onClick={() =>
					route(`/edit/${toMinutes(props.timestamp)}/${messageEncoded}`)
				}
			>
				Edit
			</Button>
			<Button onClick={() => route('/')}>New</Button>
			<a
				className={styles.shareLink}
				onClick={() => {
					try {
						navigator.share({ text: props.message })
					} catch (e) {
						// ignore
					}
				}}
			>
				<Icon className="icon-link" />
			</a>
		</div>
	)
}
